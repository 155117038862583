/** @jsx jsx */
import { jsx } from 'theme-ui'

export const RegistryCard = ({ link, storeName, contents }) => (
  <div
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    }}
  >
    <h3
      sx={{
        fontSize: [3, 4],
        fontWeight: 500,
        color: 'gray.900',
        lineHeight: '1.25',
        mb: 2,
      }}
    >
      {storeName}
    </h3>
    <ul sx={{ mb: 3 }}>
      {contents.map((item) => (
        <li
          key={item}
          sx={{
            display: 'flex',
            fontSize: [3, 2],
            fontWeight: 200,
            '::before': {
              display: 'inline-block',
              height: '1px',
              width: '8px',
              mt: '12px',
              mr: '8px',
              backgroundColor: (theme) => theme.colors.gray['600'],
            },
          }}
        >
          {item}
        </li>
      ))}
    </ul>
    <a
      href={link}
      target="blank"
      sx={{
        variant: 'buttons.secondary',
        fontWeight: 300,
        ':hover, :focus': {
          variant: 'buttons.primary',
        },
      }}
    >
      Go to this registry
    </a>
  </div>
)

export default RegistryCard
