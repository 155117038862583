/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'
import RegistryCard from '../components/registryCard'

export const Registry = () => {
  const {
    banner: {
      childImageSharp: { fluid: banner },
    },
  } = useStaticQuery(graphql`
    query {
      banner: file(
        name: { eq: "banner" }
        sourceInstanceName: { eq: "registry" }
      ) {
        childImageSharp {
          fluid(quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const registries = [
    {
      storeName: 'Zola',
      href: 'https://www.zola.com/registry/hahn',
      contents: ['Honeymoon fund', 'Date night fund', 'Adventure fund'],
    },
    {
      storeName: 'Crate&Barrel',
      href:
        'https://www.crateandbarrel.com/gift-registry/mason-hahn-and-hannah-ouellette/r6070052',
      contents: ['Cookware', 'Dinnerware', 'Kitchen tools'],
    },
    {
      storeName: 'Amazon',
      href: 'https://www.amazon.com/wedding/share/hahnestlyinlove',
      contents: ['Knife set', 'Appliances', "Everything else, it's Amazon"],
    },
  ]
  return (
    <Layout>
      <SEO title="Registry" />
      <div
        sx={{
          mt: [3, 4],
          mb: [4, 5],
        }}
      >
        <h2 sx={{ variant: 'text.smallHeading', fontWeight: '700', mb: 3 }}>
          We're registered at these places
        </h2>
        <ul
          sx={{
            display: 'grid',
            gridTemplateColumns: [
              '1fr',
              'repeat(2, minmax(0, 1fr))',
              'repeat(2, minmax(0, 1fr))',
              'repeat(4, minmax(0, 1fr))',
            ],
            gridColumnGap: 3,
            gridRowGap: 4,
          }}
        >
          {registries.map((registry) => (
            <li key={registry.href}>
              <RegistryCard
                link={registry.href}
                storeName={registry.storeName}
                contents={registry.contents}
              />
            </li>
          ))}
        </ul>
        <div
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: [4, 5],
            mb: [3, 4],
          }}
        >
          <p sx={{ textAlign: ['left', 'center'], fontWeight: 300 }}>
            To make it easier on both you and us, please ship all registry items
            to our home. Thank you!
          </p>
        </div>
        <div
          sx={{
            mx: -1,
            bg: 'gray.400',
            p: '1px',
          }}
        >
          <Img
            fluid={banner}
            sx={{
              border: '4px solid white',
            }}
          />
        </div>
      </div>
    </Layout>
  )
}

export default Registry
